import React from "react";
import styled from "styled-components";
import Link from "components/Link";

interface Props {
  locale: string;
  path: string;
}

const Breadcrumbs: React.SFC<Props> = ({ locale, path }) => {
  // Remove trailing '/'
  const pathWithoutLastSlash =
    path.charAt(path.length - 1) === "/"
      ? path.substr(0, path.length - 1)
      : path;

  // Split by '/'
  const pathPieces = pathWithoutLastSlash.split("/");
  pathPieces.shift();

  // Remove locale from pathPieces
  const prefix = pathPieces[0];
  let strippedLocale = "";
  if (prefix === locale) {
    strippedLocale = pathPieces.shift() || "";
  }

  const lastPiece = pathPieces[pathPieces.length - 1];
  const links = pathPieces.splice(0, pathPieces.length - 1);
  return (
    <List>
      <Item>
        <Link to="/">Rue Des Mille</Link>
      </Item>
      {links.map(link => (
        <Item key={link}>
          <Link
            to={`${strippedLocale !== "" ? `/${strippedLocale}` : ""}/${link}`}
          >
            {link}
          </Link>
        </Item>
      ))}
      <Item>{lastPiece}</Item>
    </List>
  );
};

const List = styled.ol`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  padding: 20px 0;
  flex-wrap: wrap;
  @media (max-width: 1280px) {
    padding-left: 20px;
  }
  @media (max-width: 1100px) {
    padding-left: 20px;
  }
  @media (max-width: 1000px) {
    padding-left: 40px;
  }
  @media (max-width: 680px) {
    padding-left: 20px;
  }
  @media (max-width: 450px) {
    padding-left: 0px;
  }
`;
const Item = styled.li`
  padding: 0;
  margin: 0;
  font-size: 12px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  line-height: 1em;
  padding-bottom: 6px;
  &:not(:last-child):after {
    content: "/";
    display: inline-block;
    padding: 0 8px;
  }
  a {
    color: #333;
    text-decoration: none;
    position: relative;
    &::after,
    &::before {
      content: "";
      display: block;
      height: 1px;
      position: absolute;
      bottom: 0;
      transition: 0.5s all;
      left: 0;
    }
    &::before {
      content: "";
      width: 100%;
      background-color: #333;
    }
    &::after {
      width: 0;
      background-color: ${({ theme }) => theme.colors.main};
    }
    &:hover {
      cursor: pointer;
      font-weight: 600;
      &::after {
        width: 100%;
      }
    }
  }
`;

export default Breadcrumbs;
