import CircleArrowLeft from "components/icons/CircleArrowLeft";
import CircleArrowRight from "components/icons/CircleArrowRight";
import ProductCard from "components/ProductCard";
import SEO from "components/SEO";
import Wrapper from "components/Wrapper";
import { graphql } from "gatsby";
import useComponentSize from "hooks/useComponentSize";
import { useLocale } from "hooks/useLocale";
import { useProductConfigurator } from "hooks/useProductConfigurator";
import { useTranslation } from "hooks/useTranslation";
import { kebabCase } from "lodash";
import React, { useEffect, useRef } from "react";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from "react-carousel";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { computeProductPath } from "utils/common/navigation";
import {
  getFirstMediaImage,
  mapMediaToImages,
  mapMediaToVideos,
} from "utils/image";
import Breadcrumbs from "../../components/Breadcrumbs";
import Gallery from "../../components/Gallery";
import Info from "../../components/Info";
import { universalBtoa } from "utils/product";

interface Props {
  location: any;
  data: {
    datoCmsProduct: any;
    shopifyProduct: any;
    datoCmsGeneral: any;
    datoCmsOtherSubjects: { nodes: any[] };
    shopifyOtherSubjects: { nodes: any[] };
    datoCmsRelatedProducts: { nodes: any[] };
    shopifyRelatedProducts: { nodes: any[] };
    allDatoCmsCollections: { nodes: any[] };
  };
  pageContext: {
    locale: string;
    defaultLocale: string;
    allPathLocales: string[];
    pagePath: string;
  };
}

/**
 * Product template wrapper
 * Just to allow useContext usage inside
 */
const ProductTemplate = ({ location, data, pageContext }: Props) => {
  const { locale, defaultLocale, pagePath } = pageContext;
  const {
    datoCmsProduct: { title, description, seo, category, handle, hidden },
    shopifyProduct,
  } = data;
  const { media, variants } = shopifyProduct;
  const { formatMessage } = useTranslation();
  const computeValidUntil = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    return date.toISOString();
  };

  return (
    <>
      <SEO
        title={(seo && seo.title) || title}
        titleSuffix={seo && seo.title ? "" : undefined}
        description={
          (seo && seo.description) ||
          `${(seo && seo.title) || title}: ${formatMessage({
            id: "product.meta.suffix",
          })}`
        }
        imageUrl={
          (seo && seo.image && seo.image.url) ||
          getFirstMediaImage(media)?.originalSrc
        }
        link={[
          { rel: "canonical", href: "https://ruedesmille.com" + pagePath },
        ]}
      >
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": ${JSON.stringify(title)},
            "image": "${getFirstMediaImage(media)?.originalSrc}",
            "description": ${JSON.stringify(description)},
            "sku":"${
              variants &&
              variants[0] &&
              variants[0].sku &&
              variants[0].sku.replace(/"/g, '\\"')
            }",
            "brand": {
              "@type": "Thing",
              "name": "Rue des Mille"
            },
            "offers": {
              "@type": "Offer",
              "priceCurrency": "EUR",
              "price": "${variants && variants[0] && variants[0].price}",
              "itemCondition": "http://schema.org/NewCondition",
              "availability": "${
                hidden || !shopifyProduct.variants.some((variant: any) => variant.availableForSale)
                  ? "http://schema.org/OutOfStock"
                  : "http://schema.org/InStock"
              }",
              "priceValidUntil": "${computeValidUntil()}",
              "url": "https://ruedesmille.com${computeProductPath(
                locale,
                defaultLocale,
                category.shopifyHandle,
                handle
              )}",
              "seller": {
                "@type": "Organization",
                "name": "Rue des Mille"
              }
            }
          }`}
        </script>
      </SEO>
      <Product location={location} data={data} />
    </>
  );
};

interface ProductProps {
  location: any;
  data: {
    datoCmsProduct: any;
    shopifyProduct: any;
    datoCmsGeneral: any;
    datoCmsOtherSubjects: { nodes: any[] };
    shopifyOtherSubjects: { nodes: any[] };
    datoCmsRelatedProducts: { nodes: any[] };
    shopifyRelatedProducts: { nodes: any[] };
    allDatoCmsCollections: { nodes: any[] };
  };
}

const Product: React.SFC<ProductProps> = ({ location, data }) => {
  const {
    datoCmsProduct,
    shopifyProduct,
    datoCmsGeneral: general,
    datoCmsOtherSubjects: { nodes: datocmsVariants },
    shopifyOtherSubjects: { nodes: shopifyVariants },
    datoCmsRelatedProducts: { nodes: datocmsRelatedProducts },
    shopifyRelatedProducts: { nodes: shopifyRelatedProducts },
    allDatoCmsCollections: { nodes: productCollections },
  } = data;

  const {
    selectedVariant,
    selectedOptions,
    customAttributes,
    extractOptionNamesOrdered,
    extractLocalizedOptions,
    reconcileVariantSelector,
    reconcileCustomText,
    reconcileEngraving,
  } = useProductConfigurator(shopifyProduct, datoCmsProduct);

  const { locale, defaultLocale } = useLocale();

  const otherSubjects = datocmsVariants.reduce(
    (acc: any, datocmsVariant: any) => {
      const correspondingShopifyProduct = shopifyVariants.find(
        (shopifyVariant) =>
          shopifyVariant.storefrontId ===
          universalBtoa(datocmsVariant.shopifyId)
      );
      const url = computeProductPath(
        locale,
        defaultLocale,
        datocmsVariant.category.handle,
        datocmsVariant.handle
      );
      return [
        ...acc,
        { ...datocmsVariant, ...correspondingShopifyProduct, url },
      ];
    },
    []
  );

  const relatedProducts = datocmsRelatedProducts.map((datocmsProduct) => ({
    dato: datocmsProduct,
    shopify: shopifyRelatedProducts.find(
      (shopifyProduct) =>
        universalBtoa(shopifyProduct.storefrontId) === datocmsProduct.shopifyId
    ),
  }));

  // Refs
  const ref = useRef(null);
  const size = useComponentSize(ref);

  const visibleSlides =
    size.width < 500 ? 1 : size.width < 740 ? 2 : size.width < 1000 ? 3 : 4;

  const images = mapMediaToImages(shopifyProduct.media)?.filter(
    (image: any) =>
      !image.altText || !kebabCase(image.altText).includes("carousel")
  );

  const videos = mapMediaToVideos(shopifyProduct.media);

  // Trigger Google Analytics product page view
  useEffect(() => {
    const productId = selectedVariant.product.shopifyId?.replace(
      "gid://shopify/Product/",
      ""
    );
    const variantId = selectedVariant.shopifyId?.replace(
      "gid://shopify/ProductVariant/",
      ""
    );
    // Delay effect execution to allow React GA initialization. This is hacky but it works
    setTimeout(() => {
      (window as any).dataLayer.push({ ecommerce: null });
      (window as any).dataLayer.push({
        event: "productDetail",
        eventCategory: "Ecommerce",
        eventAction: "Product Detail",
        ecommerce: {
          detail: {
            products: [
              {
                id: selectedVariant.sku,
                name:
                  shopifyProduct.variants.length > 1
                    ? `${shopifyProduct.title} - ${selectedVariant.title}`
                    : shopifyProduct.title,
                category: shopifyProduct.productType,
                variant: selectedVariant.title,
                price: selectedVariant.price,
                product_id: productId,
                variant_id: variantId,
              },
            ],
          },
        },
      });
    }, 200);
  }, [selectedVariant]);

  return (
    <Container>
      <Wrapper>
        <Breadcrumbs locale={locale} path={location.pathname} />
        <ProductContainer>
          <GalleryContainer>
            <Gallery
              images={images}
              videos={videos}
              selectedVariant={selectedVariant}
              datocmsProduct={datoCmsProduct}
            />
          </GalleryContainer>
          <InfoContainer>
            <Info
              datocmsProduct={datoCmsProduct}
              shopifyProduct={shopifyProduct}
              otherSubjects={otherSubjects}
              general={general}
              selectedVariant={selectedVariant}
              selectedOptions={selectedOptions}
              customAttributes={customAttributes!}
              extractOptionNamesOrdered={extractOptionNamesOrdered}
              extractLocalizedOptions={extractLocalizedOptions}
              reconcileVariantSelector={reconcileVariantSelector}
              reconcileCustomText={reconcileCustomText}
              reconcileEngraving={reconcileEngraving}
              productCollections={productCollections}
            />
          </InfoContainer>
        </ProductContainer>
        <RelatedProducts ref={ref}>
          <RelatedProductsContainer>
            <RelatedProductsTitle>
              <FormattedMessage id="label.relatedProducts" />
            </RelatedProductsTitle>
            <CarouselProvider
              totalSlides={relatedProducts.length}
              visibleSlides={visibleSlides}
            >
              <Slider>
                {relatedProducts.map((relatedProduct, index) => (
                  <Slide key={index} index={index}>
                    <ProductCard product={relatedProduct} />
                  </Slide>
                ))}
              </Slider>
              <ButtonBack>
                <CircleArrowLeft />
              </ButtonBack>
              <ButtonNext>
                <CircleArrowRight />
              </ButtonNext>
            </CarouselProvider>
          </RelatedProductsContainer>
        </RelatedProducts>
      </Wrapper>
    </Container>
  );
};

export const query = graphql`
  query productQuery(
    $locale: String
    $shopifyId: String
    $storefrontShopifyId: String
    $otherSubjectIds: [String]
    $storefrontOtherSubjectIds: [String]
    $relatedProductsIds: [String]
    $storefrontRelatedProductsIds: [String]
    $productCollections: [String]
  ) {
    datoCmsProduct(
      locale: { eq: $locale }
      shopifyId: { eq: $storefrontShopifyId }
    ) {
      shopifyId
      title
      handle
      featured
      description
      note
      noteTextNode {
        childMarkdownRemark {
          html
        }
      }
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
      category {
        shopifyHandle
        title
        _allHandleLocales {
          locale
          value
        }
      }
      relatedProducts {
        shopifyId
      }
      variants {
        shopifyId
      }
      _allHandleLocales {
        locale
        value
      }
      _allTitleLocales {
        locale
        value
      }
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
      productCustomisation
      productCustomisationCase
      productCustomisationSymbols
      productCustomisationNumberOfFields
      productCustomisationCharactersLimit
      productCustomisationProgressivePrice
      additionalCta
      additionalCtaTitle
      additionalCtaLink {
        __typename
        ... on DatoCmsProduct {
          handle
          category {
            handle
          }
        }
        ... on DatoCmsCategory {
          handle
        }
        ... on DatoCmsPage {
          handle
        }
      }
      additionalCtaCustomLink
      sizeGuide {
        title
        contentNode {
          childMarkdownRemark {
            html
          }
        }
      }
      hidden
      notForSale
      productEngraving
      productEngravingCharactersLimit
      productEngravingCase
      productEngravingSymbols
      productEngravingFont
    }
    shopifyProduct(storefrontId: { eq: $shopifyId }) {
      storefrontId
      shopifyId
      handle
      description
      productType
      title
      media {
        id
        shopifyId
        mediaContentType
        ... on ShopifyMediaImage {
          image {
            originalSrc
            altText
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        ... on ShopifyVideo {
          originalSource {
            url
          }
          preview {
            image {
              originalSrc
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      variants {
        storefrontId
        shopifyId
        availableForSale
        sku
        title
        compareAtPrice
        price
        media {
          id
          shopifyId
          mediaContentType
          ... on ShopifyMediaImage {
            image {
              originalSrc
              altText
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        selectedOptions {
          name
          value
        }
        product {
          shopifyId
        }
      }
    }
    datoCmsGeneral(locale: { eq: $locale }) {
      locale
      additionalProductDetailsNode {
        childMarkdownRemark {
          html
        }
      }
    }
    datoCmsOtherSubjects: allDatoCmsProduct(
      filter: {
        shopifyId: { in: $storefrontOtherSubjectIds }
        locale: { eq: $locale }
      }
    ) {
      nodes {
        __typename
        shopifyId
        title
        handle
        category {
          handle
        }
      }
    }
    shopifyOtherSubjects: allShopifyProduct(
      sort: { fields: [handle], order: ASC }
      filter: { storefrontId: { in: $otherSubjectIds } }
    ) {
      nodes {
        storefrontId
        shopifyId
        handle
        media {
          id
          shopifyId
          mediaContentType
          ... on ShopifyMediaImage {
            image {
              originalSrc
              altText
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
    datoCmsRelatedProducts: allDatoCmsProduct(
      filter: {
        shopifyId: { in: $storefrontRelatedProductsIds }
        locale: { eq: $locale }
      }
    ) {
      nodes {
        __typename
        shopifyId
        title
        handle
        featured
        category {
          handle
        }
      }
    }
    shopifyRelatedProducts: allShopifyProduct(
      sort: { fields: [handle], order: ASC }
      filter: { storefrontId: { in: $relatedProductsIds } }
    ) {
      nodes {
        storefrontId
        shopifyId
        handle
        description
        title
        media {
          id
          shopifyId
          mediaContentType
          ... on ShopifyMediaImage {
            image {
              originalSrc
              altText
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        variants {
          storefrontId
          title
          compareAtPrice
          price
          media {
            id
            shopifyId
            mediaContentType
            ... on ShopifyMediaImage {
              image {
                originalSrc
                altText
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
          selectedOptions {
            name
            value
          }
        }
      }
    }
    allDatoCmsCollections: allDatoCmsCollection(
      filter: { handle: { in: $productCollections }, locale: { eq: $locale } }
    ) {
      nodes {
        handle
        title
      }
    }
  }
`;

const Container = styled.div``;

const ProductContainer = styled.div`
  display: flex;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const GalleryContainer = styled.div`
  width: 500px;
  padding-bottom: 40px;
  flex: 0 0 500px;
  position: relative;
  @media (max-width: 1100px) {
    width: 480px;
    flex: 0 0 480px;
  }
  @media (max-width: 900px) {
    width: 100%;
    padding: 0 40px;
    padding-bottom: 40px;
    flex: 0 0 auto;
  }
  @media (max-width: 680px) {
    width: calc(100% + 40px);
    margin-left: -20px;
    padding: 0;
    padding-bottom: 20px;
  }
`;

const InfoContainer = styled.div`
  padding-left: 120px;
  padding-right: 80px;
  width: 100%;
  @media (max-width: 1200px) {
    padding-right: 40px;
    padding-left: 80px;
  }
  @media (max-width: 1100px) {
    padding-left: 40px;
    padding-right: 20px;
  }
  @media (max-width: 900px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: 680px) {
    padding: 0 20px;
  }
  @media (max-width: 450px) {
    padding: 0;
  }
`;

const RelatedProducts = styled.div`
  padding-top: 60px;
  position: relative;
  @media (max-width: 1280px) {
    width: calc(100% - 60px);
    margin-left: 30px;
  }
`;

const RelatedProductsContainer = styled.div`
  position: relative;
  button {
    background-color: transparent;
    height: 20px;
    width: 20px;
    border: none;
    transition: 0.3s all;
    padding: 5px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &[aria-label="previous"] {
      left: -30px;
    }
    &[aria-label="next"] {
      right: -30px;
    }
    :disabled {
      opacity: 0.3;
    }
    svg {
      stroke: ${({ theme }) => theme.colors.main};
      display: block;
      &:hover {
        stroke: #806724;
      }
    }
  }
`;

const RelatedProductsTitle = styled.div`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  color: #333;
  @media (max-width: 8600px) {
    font-size: 20px;
  }
  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

export default ProductTemplate;
